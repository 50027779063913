<template>
  <div class="main-container">
    <div class="container">
      <div class="accordion mt-4" id="accordionExample" v-for="(student, i) in information" :key="i + 1">
        <!-- Objetivo Profesional -->
        <div class="accordion-item" v-show="student.status === 'exuniversitario'">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
              aria-expanded="true" aria-controls="collapseOne">
              Objetivo Profesional
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div v-if="this.enableInput">
                <div class="row align-items-start mt-4">
                  <div class="col">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="floatingInput" v-model="student.puesto"
                        :disabled="this.enableInput" />
                      <label for="floatingInput">Posición Deseada</label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="floatingInput" v-model="student.salarioAproximado"
                        :disabled="this.enableInput" @keypress="onlyNumberSalary" />
                      <label for="floatingInput">Ingreso Mensual Deseado</label>
                    </div>
                  </div>
                </div>
                <div class="row align-items-start mt-2">
                  <div class="col">
                    <div class="form-floating mb-4">
                      <textarea class="form-control" id="floatingInput" wrap="hard"
                        v-model="student.objetivoProfesional" :disabled="this.enableInput"> </textarea>
                      <label for="floatingTextarea">Objetivo profesional</label>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="!this.enableInput">
                <div class="row align-items-start mt-4">
                  <div class="col">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="floatingInput" v-model="puesto"
                        :disabled="this.enableInput" />
                      <label for="floatingInput">Posición Deseada</label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="floatingInput" v-model="salarioAproximado"
                        :disabled="this.enableInput" @keypress="onlyNumberSalary" />
                      <label for="floatingInput">Ingreso Mensual Deseado</label>
                    </div>
                  </div>
                </div>
                <div class="row align-items-start mt-2">
                  <div class="col">
                    <div class="form-floating mb-4">
                      <textarea class="form-control" id="floatingInput" wrap="hard" v-model="objetivoProfesional"
                        :disabled="this.enableInput"> </textarea>
                      <label for="floatingTextarea">Objetivo profesional</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button id="change" class="btn btn-danger me-md-2" type="button" v-on:click="editInf()">
                  Editar
                </button>
                <button class="btn btn-success" type="button" v-on:click="updateObjetivoProfesional()">Guardar</button>
              </div>
            </div>
          </div>
        </div>
        <!-- Información Personal Comparten los 3-->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Informacion Personal
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="row align-items-start mt-4">
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" v-model="student.name" disabled />
                    <label for="floatingInput">Nombre</label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" v-model="student.lastname" disabled />
                    <label for="floatingInput">Apellido Paterno</label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" v-model="student.secondlastname"
                      disabled />
                    <label for="floatingInput">Apellido Materno</label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="date" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.date" disabled />
                    <label for="floatingInput">Fecha de Nacimiento</label>
                  </div>
                </div>
              </div>
              <div class="row align-items-start mt-2">
                <div class="col">
                  <div class="form-floating mb-4">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.curp" disabled />
                    <label for="floatingInput">CURP</label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.gender" disabled />
                    <label for="floatingInput">Género</label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.phone" :disabled="this.enableInput1" />
                    <label for="floatingInput">Celular Personal</label>
                  </div>
                </div>
                <!-- <div class="col">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="changePhone" />
                    <label for="floatingInput">Nuevo Celular Personal</label>
                  </div>
                </div> -->
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.country" disabled />
                    <label for="floatingInput">Pais de Origen</label>
                  </div>
                </div>
              </div>
              <div class="row align-items-start mt-2">
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.estadoRepublica" disabled />
                    <label for="floatingInput">Estado</label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.municipaly" disabled />
                    <label for="floatingInput">Municipio</label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.delegation" disabled />
                    <label for="floatingInput">Delegacion</label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.email" :disabled="this.enableInput1" />
                    <label for="floatingInput">Correo Electrónico</label>
                  </div>
                </div>
                <!-- <div class="col">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="changeEmail" />
                    <label for="floatingInput">Nuevo Correo Electrónico</label>
                  </div>
                </div> -->
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button id="change" class="btn btn-danger me-md-2" type="button" @click="editInf1()">
                  Editar
                </button>
                <button class="btn btn-success" type="button" v-on:click="updateinfoPersonal()"
                  disabled>Guardar</button>
              </div>
            </div>
          </div>
        </div>
        <!-- Información Escolar -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Informacion Escolar
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="row align-items-start mt-4">
                <!-- Nombre Escuela Pre-Universitario y Universitario -->
                <div class="col" v-show="student.status === 'preuniversitario' || student.status === 'universitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.school" :disabled="this.enableInput2" />
                    <label for="floatingInput">Nombre de Escuela</label>
                  </div>
                </div>
                <!--SOLO PRE-UNIVERSITARIO-->
                <div class="col" v-show="student.status === 'preuniversitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.typePreparatory" :disabled="this.enableInput2" />
                    <label for="floatingInput">Tipo de Bachillerato</label>
                  </div>
                </div>
                <!-- Nombre Escuela Lic Ex-Universitario -->
                <div class="col" v-show="student.status === 'exuniversitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.schoolLic" :disabled="this.enableInput2" />
                    <label for="floatingInput">Escuela de Licenciatura</label>
                  </div>
                </div>
                <!-- Plantel Escolar Pre-Universitario y Universitario-->
                <div class="col" v-show="student.status === 'preuniversitario' || student.status === 'universitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.plantel" :disabled="this.enableInput2" />
                    <label for="floatingInput">Plantel</label>
                  </div>
                </div>
                <!-- Carrera Lic Ex-Universitario -->
                <div class="col" v-show="student.status === 'exuniversitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.careerLic" :disabled="this.enableInput2" />
                    <label for="floatingInput">Carrera</label>
                  </div>
                </div>
                <!-- Inicio Licenciatura Ex -->
                <div class="col" v-show="student.status === 'exuniversitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.startDateLic" :disabled="this.enableInput2" />
                    <label for="floatingInput">Inicio de Licenciatura</label>
                  </div>
                </div>
                <!-- Fin Licenciatura Ex -->
                <div class="col" v-show="student.status === 'exuniversitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.endDateLic" :disabled="this.enableInput2" />
                    <label for="floatingInput">Fin de Licenciatura</label>
                  </div>
                </div>
                <!-- Promerdio PRE-UNI -->
                <div class="col" v-show="student.status === 'preuniversitario' || student.status === 'universitario'">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.promedio" :disabled="this.enableInput2" />
                    <label for="floatingInput">Promedio</label>
                  </div>
                </div>
              </div>
              <!-- Nueva fila -->
              <div class="row align-items-start mt-2">
                <!-- Carrera PreUniversitario y Universitario -->
                <div class="col" v-show="student.status === 'preuniversitario' || student.status === 'universitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.career" :disabled="this.enableInput2" />
                    <label for="floatingInput">Carrera</label>
                  </div>
                </div>
                <!-- Años Estudiando PreUniversitario y Universitario -->
                <div class="col" v-show="student.status === 'preuniversitario' || student.status === 'universitario'">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.yearActual" :disabled="this.enableInput2" />
                    <label for="floatingInput">Años Estudiando</label>
                  </div>
                </div>
                <!-- Años De Estudio PreUniversitario y Universitario -->
                <div class="col" v-show="student.status === 'preuniversitario' || student.status === 'universitario'">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.yearTotal" :disabled="this.enableInput2" />
                    <label for="floatingInput">Duración Total de Estudios</label>
                  </div>
                </div>
                <!-- Escuela Maestría ExUniversitario -->
                <div class="col" v-show="student.status === 'exuniversitario'">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.schoolMa" :disabled="this.enableInput2" />
                    <label for="floatingInput">Escuela de Maestría</label>
                  </div>
                </div>
                <!-- Carrera Maestría Ex-Universitario -->
                <div class="col" v-show="student.status === 'exuniversitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.careerMa" :disabled="this.enableInput2" />
                    <label for="floatingInput">Carrera de Maestría</label>
                  </div>
                </div>
                <!-- Inicio Maestría Ex -->
                <div class="col" v-show="student.status === 'exuniversitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.startDateMa" :disabled="this.enableInput2" />
                    <label for="floatingInput">Inicio de Maestría</label>
                  </div>
                </div>
                <!-- Fin Maestría Ex -->
                <div class="col" v-show="student.status === 'exuniversitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.endDateMa" :disabled="this.enableInput2" />
                    <label for="floatingInput">Fin de Maestría</label>
                  </div>
                </div>
              </div>
              <!-- Fila Unica ExUniversitario -->
              <div class="row align-items-start mt-2" v-show="student.status === 'exuniversitario'">
                <!-- Escuela Doctorado ExUniversitario -->
                <div class="col" v-show="student.status === 'exuniversitario'">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.schoolDoc" :disabled="this.enableInput2" />
                    <label for="floatingInput">Escuela de Doctorado</label>
                  </div>
                </div>
                <!-- Carrera Doctorado Ex-Universitario -->
                <div class="col" v-show="student.status === 'exuniversitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.careerDoc" :disabled="this.enableInput2" />
                    <label for="floatingInput">Carrera de Doctorado</label>
                  </div>
                </div>
                <!-- Inicio Licenciatura Ex -->
                <div class="col" v-show="student.status === 'exuniversitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.startDateDoc" :disabled="this.enableInput2" />
                    <label for="floatingInput">Inicio de Doctorado</label>
                  </div>
                </div>
                <!-- Fin Licenciatura Ex -->
                <div class="col" v-show="student.status === 'exuniversitario'">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"
                      v-model="student.endDateDoc" :disabled="this.enableInput2" />
                    <label for="floatingInput">Fin de Doctorado</label>
                  </div>
                </div>
              </div>
              <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button class="btn btn-danger me-md-2" type="button" @click="editInf2()">
                  Editar
                </button>
                <button class="btn btn-success" type="button">Guardar</button>
              </div> -->
            </div>
          </div>
        </div>
        <!-- Intereses Generales Comparten los 3-->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              Intereses Generales
            </button>
          </h2>
          <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <h4>Hobbies</h4>
              <div class="row align-items-start mt-4">
                <div class="col" v-for="(hobbies, i) in student.hobbies" :key="i + 1">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" v-model="hobbies.name"
                      :disabled="this.enableInput3" />
                    <label for="floatingInput">Hobbie 1</label>
                  </div>
                </div>
              </div>
              <div class="row align-items-start mt-4">
                <div class="col" v-if="student.hobbieWrite1">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" v-model="student.hobbieWrite1"
                      :disabled="this.enableInput3" />
                    <label for="floatingInput">Hobbie Adicional 1</label>
                  </div>
                </div>
                <div class="col" v-if="student.hobbieWrite2">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" v-model="student.hobbieWrite2"
                      :disabled="this.enableInput3" />
                    <label for="floatingInput">Hobbie Adicional 2</label>
                  </div>
                </div>
              </div>
              <div class="row align-items-start mt-4">
                <div class="col"></div>
              </div>
              <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button class="btn btn-danger me-md-2" type="button" @click="editInf3()">
                  Editar
                </button>
                <button class="btn btn-success" type="button" @click="submitForm()">Guardar</button>
              </div> -->
            </div>
          </div>
        </div>
        <!-- Red Social -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFive">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
              Red Social
            </button>
          </h2>
          <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <h4>Redes Sociales</h4>
              <div class="row align-items-start mt-4">
                <!-- <div class="col">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-white" id="basic-addon1"
                      style="background-color: #3b5998">Facebook</span>
                    <input type="text" class="form-control" placeholder="Username" aria-label="Username"
                      aria-describedby="basic-addon1" :disabled="this.enableInput2"/>
                  </div>
                </div>
                <div class="col">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-white" id="basic-addon1"
                      style="background-color: #dd2a7b">Instagram</span>
                    <input type="text" class="form-control" placeholder="Username" aria-label="Username"
                      aria-describedby="basic-addon1" :disabled="this.enableInput2"/>
                  </div>
                </div>-->
              </div>
              <div class="row align-items-start mt-4">
                <div class="col">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-white" id="basic-addon1"
                      style="background-color: #0e76a8">Linkedin</span>
                    <input type="text" class="form-control" placeholder="Username" aria-label="Username"
                      aria-describedby="basic-addon1" v-model="student.linkedin" :disabled="this.enableInput4" />
                  </div>
                </div>
                <div class="col">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-white" id="basic-addon1"
                      style="background-color: #0e76a8">Linkedin</span>
                    <input type="url" class="form-control" placeholder="Username" aria-label="Username"
                      aria-describedby="basic-addon1" v-model="changeLinkedin" />
                  </div>
                </div>
                <!-- <div class="col">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-white" id="basic-addon1"
                      style="background-color: #25d366">WhatsApp</span>
                    <input type="text" class="form-control" placeholder="Username" aria-label="Username"
                      aria-describedby="basic-addon1" :disabled="this.enableInput2"/>
                  </div>
                </div>-->
              </div>
              <div class="row align-items-start mt-4">
                <!-- <div class="col">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-white" id="basic-addon1"
                      style="background-color: #0088cc">Telegram</span>
                    <input type="text" class="form-control" placeholder="Username" aria-label="Username"
                      aria-describedby="basic-addon1" :disabled="this.enableInput2"/>
                  </div>
                </div> -->
                <div class="col"></div>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button class="btn btn-danger me-md-2" type="button" @click="editInf4()">
                  Editar
                </button>
                <button class="btn btn-success" type="button" v-on:click="updateLinkedin()" disabled>Guardar</button>
              </div>
            </div>
          </div>
        </div>
        <!-- Documentos -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSix">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
              Actualización de Documentos
            </button>
          </h2>
          <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <!-- <div style="display: flex; justify-content: center; align-items: center;">
                <p style="color: #1b4f99; font-weight: bold">
                  Los archivos admitidos deberán ser PDF con un peso máximo de 2mb.
                </p>
              </div> -->
              <div style="display:flex; justify-content: flex-start; align-items: center; width: 20%;"
                v-if="student?.filecv">
                <a class="J-btn auto" title="CV" target="_blank" :href="student.filecv?.link">
                  <box-icon name='file-pdf' type="solid" color="#0e64ed"></box-icon>
                  <span>CV</span>
                </a>
              </div>
              <div style="display:flex; justify-content: flex-start; align-items: center; width: 20%;"
                v-if="student?.filecomprobantes">
                <a class="J-btn auto" title="CV" target="_blank" :href="student.filecomprobantes?.link">
                  <box-icon name='file-pdf' type="solid" color="#0e64ed"></box-icon>
                  <span>Comprobante</span>
                </a>
              </div>
              <div class="row align-items-start mt-4">
                <div class="col" v-show="student.status === 'exuniversitario'">
                  <UploadPartials title="CONSTANCIA DE TERMINACIÓN DE ESTUDIOS, TITULÓ O CÉDULA"
                    @data="cambioComprobantes">
                  </UploadPartials>
                </div>
                <div class="col" v-show="student.status != 'exuniversitario'">
                  <UploadPartials title="Constancia de Estudios Vigente" @data="cambioComprobantes">
                  </UploadPartials>
                </div>
                <div class="col">
                  <UploadPartials title="CV" @data="cambioCv"></UploadPartials>
                </div>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button class="btn btn-success" type="button" v-on:click="updatedocumentacion()">Guardar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UserService } from "@/services";
//import InputPartials from "@/components/partials/Input.partials.vue";
import UploadPartials from "@/components/partials/upload.partials.vue";

export default {
  mounted() {
    this.extractionInformation();
    this.dataUser = JSON.parse(localStorage.getItem("user") || null);
  },
  data: () => {
    return {
      //Objetivo Profesional
      puesto: "",
      salarioAproximado: "",
      objetivoProfesional: "",
      enableInput: true,
      enableInput1: true,
      enableInput2: true,
      enableInput3: true,
      enableInput4: true,
      showInformation: false,
      showUpdates: false,
      showStatus: false,
      showContact: false,
      showDirection: false,
      showSchool: false,
      showOptiones: false,
      information: [],
      changeLinkedin: "",
      phone: "",
      changePhone: "",
      email: "",
      changeEmail: "",
      filecv: "",
      changefilecv: "",
      filecomprobantes: "",
      changefilecomprobantes: "",
      facebook: "",
      instagram: "",
      linkedin: "",
      twitter: "",
      description: "",
      dataUser: {},
      datos: {},
      dataHabilidades: [
        {
          "name": "ABEJAS (CRIANZA DE ABEJAS O APICULTURA)"
        },
        {
          "name": "ACROYOGA"
        },
        {
          "name": "ACTUAR EN TEATRO"
        },
        {
          "name": "ACUARIOS"
        },
        {
          "name": "AEROBIC"
        },
        {
          "name": "AEROMODELISMO"
        },
        {
          "name": "AEROSTACION"
        },
        {
          "name": "AIKIDO"
        },
        {
          "name": "AIRSOFT"
        },
        {
          "name": "AJEDREZ"
        },
        {
          "name": "ANIMACION ( CREACION DE CINE DE ANIMACION Y DIBUJOS ANIMADOS)"
        },
        {
          "name": "AQUAGYM"
        },
        {
          "name": "AROMATERAPIA"
        },
        {
          "name": "ARTE (AFICION A LAS ARTES PLASTICAS)"
        },
        {
          "name": "ARTE DIGITAL"
        },
        {
          "name": "ARTE:ASISTIR A ESPECTACULOS CULTURALES (TEATRO,MUSICA,DANZA,OPERA,ZARZUELA)"
        },
        {
          "name": "ASOCIACIONES (PERTENENCIA A GRUPOS SOCIALES DE TIPO ARTISTICO,DEPORTIVO,FESTIVO,ETC)"
        },
        {
          "name": "ASTROLOGIA"
        },
        {
          "name": "ASTRONOMIA"
        },
        {
          "name": "ATLETISMO"
        },
        {
          "name": "AUDIOLIBROS"
        },
        {
          "name": "AUTOCARAVANAS"
        },
        {
          "name": "AUTOMOVILES CLASICOS/ANTIGUOS"
        },
        {
          "name": "AVENTURA( ACTIVIDADES DE )"
        },
        {
          "name": "AVIACION DEPORTIVA (Y RECREATIVA)"
        },
        {
          "name": "AVICULTURA (CRIA DE GALLINAS)CASERA"
        },
        {
          "name": "AVISTAMIENTO DE AVES"
        },
        {
          "name": "BACKGAMMON"
        },
        {
          "name": "BADMINTON"
        },
        {
          "name": "BAILAR"
        },
        {
          "name": "BAILE CON BARRA (POLE DANCE, POLE FITNESS)"
        },
        {
          "name": "BALLET"
        },
        {
          "name": "BALONCESTO"
        },
        {
          "name": "BALONMANO"
        },
        {
          "name": "BALONMANO PLAYA"
        },
        {
          "name": "BANDA O GRUPO MUSICALES"
        },
        {
          "name": "BARCO DENTRO DE UNA BOTELLA"
        },
        {
          "name": "BARCOS DE MOTOR"
        },
        {
          "name": "BARRANQUISMO"
        },
        {
          "name": "BATALLAS DE GALLOS"
        },
        {
          "name": "BATIK Y ESTAMPACION DE TEJIDOS"
        },
        {
          "name": "BEISBOL"
        },
        {
          "name": "BELLEZA Y ESTETICA (AFICION A)"
        },
        {
          "name": "BIATLON"
        },
        {
          "name": "BIBLIOFILIA"
        },
        {
          "name": "BICICLETA (CICLISMO)"
        },
        {
          "name": "BICICLETA DE MONTAÑA"
        },
        {
          "name": "BIKEJORING"
        },
        {
          "name": "BILLAR"
        },
        {
          "name": "BINGO"
        },
        {
          "name": "BLOG (Y VIDEOBLOG)"
        },
        {
          "name": "BMX (BICICLETA DE MOTOCROSS)"
        },
        {
          "name": "BODYBOARD"
        },
        {
          "name": "BOLOS (BOWLING, BOLICHE)"
        },
        {
          "name": "BONSAI (ARBOLES ENANOS)"
        },
        {
          "name": "BORDADO SOBRE PAPEL"
        },
        {
          "name": "BOXEO"
        },
        {
          "name": "BRICOLAJE O DIY"
        },
        {
          "name": "BUCEO (SUBMARINISMO)"
        },
        {
          "name": "BUCEO» EN CONTENEDORES"
        },
        {
          "name": "CABALLO (MONTAR, HIPICA, EQUITACION)"
        },
        {
          "name": "CALIGRAFIA ARTISTICA Y LETTERING"
        },
        {
          "name": "CALISTENIA"
        },
        {
          "name": "CAMPISMO"
        },
        {
          "name": "CAMPO A TRAVES"
        },
        {
          "name": "CANARICULTURA"
        },
        {
          "name": "CANICROSS"
        },
        {
          "name": "CANTAR"
        },
        {
          "name": "CAPOEIRA"
        },
        {
          "name": "CARPINTERIA"
        },
        {
          "name": "CARRERAS POR MONTAÑA"
        },
        {
          "name": "CARROVELA Y BLOKART (LAND SAILING)"
        },
        {
          "name": "CASAS DE MUÑECAS (CONSTRUCCION DE)"
        },
        {
          "name": "CASTILLOS Y FORTIFICACIONES (INTERES POR)"
        },
        {
          "name": "CATA DE CERVEZA"
        },
        {
          "name": "CATA DE VINO"
        },
        {
          "name": "CAZA"
        },
        {
          "name": "CERAMICA"
        },
        {
          "name": "CERVEZA (FABRICACION CASERA DE)"
        },
        {
          "name": "CESTERIA"
        },
        {
          "name": "CETRERIA"
        },
        {
          "name": "CHALKPAINT (PINTURA DE TIZA)"
        },
        {
          "name": "CHI KUNG O QUI GONG"
        },
        {
          "name": "CIANOTIPIA"
        },
        {
          "name": "CICLOTURISMO"
        },
        {
          "name": "CIENCIA (AFICION A LA)"
        },
        {
          "name": "CINE (AFICION AL CINE, VER CINE)"
        },
        {
          "name": "COCINA"
        },
        {
          "name": "COLECCIONISMO (SELLOS,MONEDAS,ANTIGÜEDADES,ETC)"
        },
        {
          "name": "COLOREAR LIBROS"
        },
        {
          "name": "COMETAS (VOLAR COMETAS)"
        },
        {
          "name": "COMICS (CREACION DE )"
        },
        {
          "name": "COMICS (LECTURA DE)"
        },
        {
          "name": "COMIDISTA (FOODIE)"
        },
        {
          "name": "COMPOSICION MUSICAL"
        },
        {
          "name": "COMPOSTAJE"
        },
        {
          "name": "COMPRAR (O VENDER: ROPA, COMPLEMENTOS, OBJETOS, ETC)"
        },
        {
          "name": "CONDUCCIÓN DE AUTOMÓVILES (AUTOMOVILISMO DEPORTIVO)"
        },
        {
          "name": "CONDUCCION DE MOTOCICLETAS (MOTOCICLISMO)Y MOTEROS"
        },
        {
          "name": "CONFERENCIAS(ASISTIR A)"
        },
        {
          "name": "CORO"
        },
        {
          "name": "CORRER (RUNNING)"
        },
        {
          "name": "COSMETICA CASERA"
        },
        {
          "name": "COSPLAY"
        },
        {
          "name": "COSTURA, CORTE Y CONFECCION"
        },
        {
          "name": "CRISTAL TEÑIDO"
        },
        {
          "name": "CROQUET"
        },
        {
          "name": "CROSSFIT"
        },
        {
          "name": "CRUCEROS (VIAJES DE)"
        },
        {
          "name": "CUBO DE RUBIK"
        },
        {
          "name": "CUERO (CREACIONES CON)"
        },
        {
          "name": "CULTURISMO (BODY BUILDING)"
        },
        {
          "name": "CURLING"
        },
        {
          "name": "CUSTOMIZACION DE ROPA"
        },
        {
          "name": "CUSTOMIZACION Y RESTAURACION DE BICICLETAS"
        },
        {
          "name": "DAMAS (JUEGO DE)"
        },
        {
          "name": "DANZA AEREA"
        },
        {
          "name": "DARDOS (LANZAR DARDOS)"
        },
        {
          "name": "DECORACION DE INTERIORES"
        },
        {
          "name": "DECOUPAGE (DECORACION DE SUPERFICIES)"
        },
        {
          "name": "DEPORTE (AFICION AL)"
        },
        {
          "name": "DEPORTE (ASISTIR A ESPECTACULOS DEPORTIVOS)"
        },
        {
          "name": "DEPORTE (VER DEPORTE EN TV, ONLINE, REVISTAS O PERIODICOS, RADIO)"
        },
        {
          "name": "DEPORTES DE FANTASIA (LIGA DE FANTASIA)"
        },
        {
          "name": "DIARIO (ESCRIBIR UN)"
        },
        {
          "name": "DIBUJO ARTISTICO"
        },
        {
          "name": "DIORAMAS (Y BELENES)"
        },
        {
          "name": "DIRIGIR, ENTRENAR, GESTIONAR"
        },
        {
          "name": "DISC GOLF"
        },
        {
          "name": "DISEÑO DE ROPA (MODA)"
        },
        {
          "name": "DISEÑO Y CREACION DE PAGINAS WEB"
        },
        {
          "name": "DJ (DISK JOCKEY)"
        },
        {
          "name": "DOCUMENTALES (AFICION A LOS)"
        },
        {
          "name": "DOMINO"
        },
        {
          "name": "ELECTRONICA"
        },
        {
          "name": "ENCUADERNACION DE LIBROS"
        },
        {
          "name": "ENMARCAR CUADROS"
        },
        {
          "name": "ENTOMOLOGIA (AFICION A LA)"
        },
        {
          "name": "ESCALADA"
        },
        {
          "name": "ESCAPE ROOM"
        },
        {
          "name": "ESCRIBIR LITERATURA (PROSA,POESIA,TEATRO,"
        },
        {
          "name": "ENSAYO,GUIONES)"
        },
        {
          "name": "ESCULTURA"
        },
        {
          "name": "ESGRIMA"
        },
        {
          "name": "ESMALTES SOBRE METAL (AL FUEGO)"
        },
        {
          "name": "ESPELEOLOGIA"
        },
        {
          "name": "ESPORTS"
        },
        {
          "name": "ESQUI ALPINO"
        },
        {
          "name": "ESQUI DE FONDO O NORDICO"
        },
        {
          "name": "ESQUI NAUTICO O ACUATICO"
        },
        {
          "name": "ESTAMPACION BOTANICA"
        },
        {
          "name": "ESTUDIAR (IDIOMAS, OTRAS ASIGNATURAS,ETC)"
        },
        {
          "name": "EXPLORACION URBANA (URBEX)"
        },
        {
          "name": "FLAMENCO"
        },
        {
          "name": "FLOORBALL (O UNIHOCKEY)"
        },
        {
          "name": "FLORES KANZASHI"
        },
        {
          "name": "FLORES SECAS (TRABAJAR CON)"
        },
        {
          "name": "FLYBOARD"
        },
        {
          "name": "FOSILES (BUSQUEDA DE)"
        },
        {
          "name": "FOTOGRAFIA"
        },
        {
          "name": "FRISBEE (DISCO VOLADOR)"
        },
        {
          "name": "FRONTON"
        },
        {
          "name": "FUTBOL"
        },
        {
          "name": "FUTBOL AMERICANO"
        },
        {
          "name": "FUTBOL SALA"
        },
        {
          "name": "FUTBOLIN"
        },
        {
          "name": "GENEALOGIA E HISTORIA FAMILIAR"
        },
        {
          "name": "GEOCACHING Y BUSQUEDA DE TESOROS"
        },
        {
          "name": "GIMNASIA (ARTISTICA,RITMICA,)"
        },
        {
          "name": "GIMNASIA DE MANTENIMIENTO Y FITNESS"
        },
        {
          "name": "GLOBOS (TRABAJO CON GLOBOS MOLDEABLES)"
        },
        {
          "name": "GO (JUEGO)"
        },
        {
          "name": "GOLF"
        },
        {
          "name": "GRABADOS ARTISTICOS"
        },
        {
          "name": "GRAFFITI Y ARTE URBANO"
        },
        {
          "name": "GROUNDHOPPING («IR DE ESTADIO EN ESTADIO»)"
        },
        {
          "name": "HACER VINO"
        },
        {
          "name": "HALTEROFILIA (LEVANTAMIENTO DE PESAS)"
        },
        {
          "name": "HAPKIDO"
        },
        {
          "name": "HIDROPONIA (CULTIVO EN LIQUIDO)"
        },
        {
          "name": "HISTORIA (AFICION A LA)"
        },
        {
          "name": "HOCKEY DE MESA (AIR HOCKEY)"
        },
        {
          "name": "HOCKEY SOBRE HIELO"
        },
        {
          "name": "HOCKEY SOBRE HIERBA"
        },
        {
          "name": "HOCKEY SOBRE PATINES"
        },
        {
          "name": "HOCKEY SUBACUATICO"
        },
        {
          "name": "HORTICULTURA (AGRICULTURA)"
        },
        {
          "name": "HUERTO CASERO"
        },
        {
          "name": "HYDROSPEED"
        },
        {
          "name": "IKEBANA ( Y ARTE FLORAL)"
        },
        {
          "name": "INTERNET (NAVEGAR POR)"
        },
        {
          "name": "INVERTIR EN BOLSA"
        },
        {
          "name": "JABON (HACER)"
        },
        {
          "name": "JARDINERIA"
        },
        {
          "name": "JET SURF"
        },
        {
          "name": "JIU JITSU"
        },
        {
          "name": "JOYAS Y BISUTERIA (CREACION DE)"
        },
        {
          "name": "JUDO"
        },
        {
          "name": "JUEGOS CON DISP"
        },
        {
          "name": "OSITIVOS ELECTRONICOS (PC, TABLET, MOVIL)"
        },
        {
          "name": "JUEGOS CON FICHAS (GENERAL)"
        },
        {
          "name": "JUEGOS DE CARTAS O NAIPES"
        },
        {
          "name": "JUEGOS DE MESA (GENERAL)"
        },
        {
          "name": "JUEGOS DE MESA TEMATICO"
        },
        {
          "name": "JUEGOS DE ROL"
        },
        {
          "name": "JUGGER"
        },
        {
          "name": "KANGOO JUMP"
        },
        {
          "name": "KARAOKE"
        },
        {
          "name": "KARATE"
        },
        {
          "name": "KAYAK POLO"
        },
        {
          "name": "KENDO"
        },
        {
          "name": "KENPO"
        },
        {
          "name": "KICK BOXING"
        },
        {
          "name": "KINTSUGI"
        },
        {
          "name": "KITESURF"
        },
        {
          "name": "KOBUDO"
        },
        {
          "name": "KOKEDAMAS (PLANTAS SOBRE MUSGO)"
        },
        {
          "name": "KORFBALL"
        },
        {
          "name": "KUNG FU"
        },
        {
          "name": "LABORES (PUNTO,CROCHET, BORDADO, ENCAJE,ETC)"
        },
        {
          "name": "LASER COMBAT"
        },
        {
          "name": "LECTURA"
        },
        {
          "name": "LEGO (CONSTRUCCIONES CON PIEZAS)"
        },
        {
          "name": "LIBROS POP UP (CREACION DE)"
        },
        {
          "name": "LIMPIEZA DE AUTOMOVILES («DETAILING»)"
        },
        {
          "name": "LOCK PICKING (ABRIR CERRADURAS)"
        },
        {
          "name": "LOMOGRAFIA"
        },
        {
          "name": "LOTERIA"
        },
        {
          "name": "LUCHA (OLIMPICA, GRECORROMANA)"
        },
        {
          "name": "MADERA (TALLAS EN MADERA)"
        },
        {
          "name": "MAGIA (ILUSIONISMO, PRESTIDIGITACION)"
        },
        {
          "name": "MAHJONG (JUEGO DE FICHAS CHINO)"
        },
        {
          "name": "MALABARES"
        },
        {
          "name": "MANGA Y ANIME"
        },
        {
          "name": "MANUALIDADES (TRABAJOS MANUALES)"
        },
        {
          "name": "MARCHA O PASEO NORDICO (NORDIC WALKING)"
        },
        {
          "name": "MARIONETAS (TEATRO DE)"
        },
        {
          "name": "MEDITACION"
        },
        {
          "name": "MERCADILLOS/FLEA MARKETS"
        },
        {
          "name": "MERMELADAS (PREPARAR)"
        },
        {
          "name": "METALES (BUSQUEDA DE)"
        },
        {
          "name": "METEOROLOGÍA (AFICIÓN A LA)"
        },
        {
          "name": "MINDFULNESS O ATENCIÓN PLENA"
        },
        {
          "name": "MINERALES (BUSQUEDA DE)"
        },
        {
          "name": "MODA (AFICION A LA MODA)"
        },
        {
          "name": "MODELISMO (AVIONES,COCHES,BARCOS,DRONES, FIGURAS,ETC)"
        },
        {
          "name": "MODELISMO CON CERILLAS"
        },
        {
          "name": "MONTAÑISMO/ALPINISMO"
        },
        {
          "name": "MOTO ACUATICA"
        },
        {
          "name": "MOTOR (AFICION A TEMAS DEL)"
        },
        {
          "name": "MUÑECAS (HACER)"
        },
        {
          "name": "MUSHING"
        },
        {
          "name": "MUSICA (AFICION A LA)"
        },
        {
          "name": "NADAR (NATACION)"
        },
        {
          "name": "NATURALEZA (INTERES POR LA)"
        },
        {
          "name": "OBSERVACION DE TRENES Y AVIONES"
        },
        {
          "name": "ORIENTACION"
        },
        {
          "name": "ORIGAMI (O PAPIROFLEXIA)"
        },
        {
          "name": "PADBOL"
        },
        {
          "name": "PADDLE SURF (SURF A REMO)"
        },
        {
          "name": "PADEL"
        },
        {
          "name": "PAINTBALL"
        },
        {
          "name": "PALOMAS (CRIA DE PALOMAS, COLOMBOFILIA Y COLOMBICULTURA)"
        },
        {
          "name": "PAPEL MACHE"
        },
        {
          "name": "PARACAIDISMO"
        },
        {
          "name": "PARAMOTOR"
        },
        {
          "name": "PARAPENTE"
        },
        {
          "name": "PARKOUR (FREERUNNING)"
        },
        {
          "name": "PASATIEMPOS (CRUCIGRAMAS, SUDOKUS, INFANTILES, MEMORIA, ETC)"
        },
        {
          "name": "PASEAR"
        },
        {
          "name": "PATCHWORK Y COLCHAS"
        },
        {
          "name": "PATINAJE SOBRE HIELO"
        },
        {
          "name": "PATINAJE SOBRE RUEDAS (ROLLER)"
        },
        {
          "name": "PATINETE ELECTRICO"
        },
        {
          "name": "PERFUME CASERO"
        },
        {
          "name": "PERIODISMO AFICIONADO"
        },
        {
          "name": "PERROS (CONCURSOS/EXHIBICIONES)"
        },
        {
          "name": "PESCA"
        },
        {
          "name": "PESCA SUBMARINA"
        },
        {
          "name": "PETANCA"
        },
        {
          "name": "PICKLEBALL"
        },
        {
          "name": "PILATES"
        },
        {
          "name": "PING PONG O TENIS DE MESA"
        },
        {
          "name": "PINTURA ARTISTICA"
        },
        {
          "name": "PINTURA DE FIGURAS (POLICROMADO,DORADO)"
        },
        {
          "name": "PINTURA SOBRE SEDA"
        },
        {
          "name": "PIRAGÜISMO (CANOISMO/CANOTAJE/KAYAKISMO)"
        },
        {
          "name": "PLANTAS DE INTERIOR"
        },
        {
          "name": "PLANTAS SILVESTRES, INCLUIDOS ARBOLES Y ARBUSTOS (AFICION A LAS)"
        },
        {
          "name": "PODCASTS (AFICION/CREACION)"
        },
        {
          "name": "POLO"
        },
        {
          "name": "PORCELANA FRIA"
        },
        {
          "name": "POWERLIFTING"
        },
        {
          "name": "PRODUCCION MUSICAL"
        },
        {
          "name": "PROGRAMACION INFORMATICA"
        },
        {
          "name": "PUROS HABANOS(CATA DE)"
        },
        {
          "name": "PUZZLES"
        },
        {
          "name": "QUAD Y BUGGY"
        },
        {
          "name": "RADIO (AFICION A LA)"
        },
        {
          "name": "RADIOAFICION"
        },
        {
          "name": "RAFTING"
        },
        {
          "name": "RAPEL"
        },
        {
          "name": "RAQUETAS DE NIEVE"
        },
        {
          "name": "REALIDAD VIRTUAL (JUEGOS DE)"
        },
        {
          "name": "RECICLAJE CREATIVO"
        },
        {
          "name": "REDES SOCIALES (FACEBOOK, YOUTUBE,TWITTER,INSTAGRAM,ETC)"
        },
        {
          "name": "REIKI (TECNICA ESPIRITUAL SANADORA)"
        },
        {
          "name": "REMO DEPORTIVO"
        },
        {
          "name": "REPOSTERIA/PASTELERIA Y CUPCAKES"
        },
        {
          "name": "REPUJADO DE METAL"
        },
        {
          "name": "RESTAURACION DE COCHES CLASICOS"
        },
        {
          "name": "RESTAURACION DE MUEBLES"
        },
        {
          "name": "REVISTAS Y PERIODICOS (LEER)"
        },
        {
          "name": "ROBOTICA"
        },
        {
          "name": "ROLLER DERBY"
        },
        {
          "name": "ROLLERSKI Y CROSS SKATING"
        },
        {
          "name": "RUGBY"
        },
        {
          "name": "SALUD (INTERES POR TEMAS DE SALUD)"
        },
        {
          "name": "SCALEXTRIC"
        },
        {
          "name": "SCRAPBOOK (ALBUM DE RECORTES)"
        },
        {
          "name": "SENDERISMO"
        },
        {
          "name": "SERIES DE TV (AFICION A LAS SERIES)"
        },
        {
          "name": "SERIGRAFIA"
        },
        {
          "name": "SETAS (BUSQUEDA O RECOLECCIÓN DE)"
        },
        {
          "name": "SKATEBOARDING"
        },
        {
          "name": "SLIME (JUGAR CON SLIME, UNA MASA VISCOSA )"
        },
        {
          "name": "SNOWBIKE"
        },
        {
          "name": "SNOWBOARD"
        },
        {
          "name": "SOFTBALL"
        },
        {
          "name": "SPEEDRIDING"
        },
        {
          "name": "SPINNING"
        },
        {
          "name": "SQUASH"
        },
        {
          "name": "SUBASTAS"
        },
        {
          "name": "SURF"
        },
        {
          "name": "TAEKWONDO"
        },
        {
          "name": "TAICHI"
        },
        {
          "name": "TAMPONES O SELLOS DE CAUCHO (CREACION CON)"
        },
        {
          "name": "TAROT"
        },
        {
          "name": "TATUAJES"
        },
        {
          "name": "TAXIDERMIA"
        },
        {
          "name": "TEJER (CON UN TELAR CASERO)"
        },
        {
          "name": "TEJO (JUEGO COLOMBIANO)"
        },
        {
          "name": "TENIS"
        },
        {
          "name": "TINTADO/TEÑIDO DE TEJIDOS"
        },
        {
          "name": "TIRO CON ARCO"
        },
        {
          "name": "TIRO CON TIRACHINAS"
        },
        {
          "name": "TIRO DE HACHA"
        },
        {
          "name": "TIRO DEPORTIVO CON ARMA DE FUEGO"
        },
        {
          "name": "TOCAR UN INSTRUMENTO MUSICAL"
        },
        {
          "name": "TOROS (AFICION A LOS)"
        },
        {
          "name": "TOSTAR CAFE"
        },
        {
          "name": "TRANSFERENCIA DE IMAGENES"
        },
        {
          "name": "TRENES A ESCALA"
        },
        {
          "name": "TRIATLON"
        },
        {
          "name": "TUFTING"
        },
        {
          "name": "UÑAS (DECORACION DE)"
        },
        {
          "name": "VEHICULOS DE CONTROL REMOTO (RC)"
        },
        {
          "name": "VELA DEPORTIVA Y RECREATIVA"
        },
        {
          "name": "VELAS (CREACION DE VELAS)"
        },
        {
          "name": "VIAJAR"
        },
        {
          "name": "VIAJAR LENTAMENTE (SLOW TRAVEL)"
        },
        {
          "name": "VIAJES DE AVENTURA"
        },
        {
          "name": "VIAJES TEMATICOS"
        },
        {
          "name": "VIDEO (REALIZACION DE VIDEOS)"
        },
        {
          "name": "VIDEOJUEGOS"
        },
        {
          "name": "VISITAR MONUMENTOS Y YACIMIENTOS ARQUEOLÓGICOS"
        },
        {
          "name": "VISITAR MUSEOS Y EXPOSICIONES"
        },
        {
          "name": "VISITAS URBANAS"
        },
        {
          "name": "VOLEIBOL"
        },
        {
          "name": "VOLEY PLAYA"
        },
        {
          "name": "VOLUNTARIADO AMBIENTAL"
        },
        {
          "name": "VOLUNTARIADO ANIMAL"
        },
        {
          "name": "VOLUNTARIADO CULTURAL"
        },
        {
          "name": "VOLUNTARIADO EN DEPORTE"
        },
        {
          "name": "VOLUNTARIADO SOCIAL"
        },
        {
          "name": "VUELO A VELA (VUELO SIN MOTOR)"
        },
        {
          "name": "WAKEBOARD"
        },
        {
          "name": "WATERPOLO"
        },
        {
          "name": "WINDSURF"
        },
        {
          "name": "YOGA"
        },
        {
          "name": "YOGA AEREO"
        },
        {
          "name": "YOUTUBER"
        },
        {
          "name": "ZUMBA"
        },
      ],
    };
  },

  components: {
    //InputPartials,
    UploadPartials,
  },

  methods: {
    async extractionInformation() {
      await UserService.getStudent().then((response) => {
        this.information = response.data;
      });
    },

    informationTotal() {
      const data = {
        facebook: this.facebook,
        instagram: this.instagram,
        linkedin: this.linkedin,
        twitter: this.twitter,
        id: this.information._id,
        description: this.description,
      };
      UserService.postInformationTotal(data)
        .then((response) => {
          this.$swal({
            position: "top-center",
            icon: "success",
            title: response.data.msg,
            showConfirmation: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          this.$swal({
            position: "top-center",
            icon: "error",
            title: error.response.data.msg,
            showConfirmation: false,
            timer: 1500,
          });
        });
    },
    editInf() {
      this.enableInput == true ? this.enableInput = false : this.enableInput = true;
    },
    editInf1() {
      this.enableInput1 == true ? this.enableInput1 = false : this.enableInput1 = true;
    },
    editInf2() {
      this.enableInput2 == true ? this.enableInput2 = false : this.enableInput2 = true;
    },
    editInf3() {
      this.enableInput3 == true ? this.enableInput3 = false : this.enableInput3 = true;
    },
    editInf4() {
      this.enableInput4 == true ? this.enableInput4 = false : this.enableInput4 = true;
    },
    onlyNumberSalary($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 44) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    updateObjetivoProfesional() {
      const user = JSON.parse(localStorage.getItem("user") || null);
      const { _id } = user;
      let updateObjetivo = {
        puesto: this.puesto,
        salarioAproximado: this.salarioAproximado,
        objetivoProfesional: this.objetivoProfesional,
        studentId: this.$route.params.studentId = _id,
      }
      UserService.updateObjetivoProfesional(updateObjetivo).then((response) => {
        this.enableInput = true;
        window.location.href = "/informationstudent";
        this.$swal({
          position: "top-center",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }).catch((e) => {
        this.$swal({
          position: "top-center",
          icon: "error",
          title: e.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    },
    updateLinkedin() {
      const user = JSON.parse(localStorage.getItem("user") || null);
      const { _id } = user;
      let updateLinkedin = {
        studentId: this.$route.params.studentId = _id,
        linkedin: this.changeLinkedin
      }
      UserService.updateSocialLinkedin(updateLinkedin).then((response) => {
        window.location.href = "/informationstudent";
        this.$swal({
          position: "top-center",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }).catch((e) => {
        this.$swal({
          position: "top-center",
          icon: "error",
          title: e.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    },
    updateinfoPersonal() {
      const user = JSON.parse(localStorage.getItem("user") || null);
      const { _id } = user;
      let updateInformacionP = {
        studentId: this.$route.params.studentId = _id,
        phone: this.changePhone,
        email: this.changeEmail,
      }
      UserService.updateInformacionPersonal(updateInformacionP).then((response) => {
        window.location.href = "/informationstudent";
        this.$swal({
          position: "top-center",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }).catch((e) => {
        this.$swal({
          position: "top-center",
          icon: "error",
          title: e.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    },
    cambioComprobantes(data) {
      this.changefilecomprobantes = data;
    },
    cambioCv(data) {
      this.changefilecv = data;
    },
    updatedocumentacion() {
      const user = JSON.parse(localStorage.getItem("user") || null);
      const { _id } = user;
      let updateDocumentos = {
        studentId: this.$route.params.studentId = _id,
        filecv: this.changefilecv,
        filecomprobantes: this.changefilecomprobantes,
      }
      UserService.updateDocumentacion(updateDocumentos).then((response) => {
        window.location.href = "/informationstudent";
        this.$swal({
          position: "top-center",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }).catch((e) => {
        this.$swal({
          position: "top-center",
          icon: "error",
          title: e.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    },
  },
};
</script>
<style land="scss" scoped>
@import "../../styles/profile.scss";
</style>
